.about-me-summary {
  text-align: justify;
  padding: 10px 0;
}
.about-me-wrapper {
  box-shadow: none !important;
  padding-top: 85px !important;
  background-color: #1a1a1d !important;
  height: 100vh !important;
  border-radius: 0 !important;
}
p,
a {
  color: #fff;
}
h2 {
  color: #c3073f;
}
.profile-pic {
  border-radius: 50%;
}

.about-me-wrapper-mobile {
  box-shadow: none !important;
  padding-top: 110px !important;
  background-color: #1a1a1d !important;
  height: 100vh !important;
  border-radius: 0 !important;
  scroll-snap-align: start;
}
